<template>
    <div>
        <page-header :title="`Thêm mới người phụ trách danh mục`" :items="breadcrumbs" />
        <div v-if="!loading">
            <b-row>
                <b-col lg="12">
                    <b-card lg="6">
                        <b-form-group>
                            <label>Tên danh mục <span class="red">*</span></label>
                            <b-form-select v-model="form.name" aria-placeholder="a">
                                <b-form-select-option :value="null" disabled>Danh mục</b-form-select-option>
                                <option v-for="(category) in categories" :key="category.id" :value="category.name"
                                    :disabled="check_categories.includes(category.name)"
                                    :class="[{'style-disabled': check_categories.includes(category.name)}]">
                                    {{ category.name }}
                                    <span
                                        v-if="check_categories.includes(category.name) && category.name != form.name">(đã
                                        có người phụ trách)</span>
                                </option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group>
                            <label>Người phụ trách <span class="red">*</span></label>
                            <b-form-select v-model="form.in_charge" aria-placeholder="a">
                                <b-form-select-option :value="null" disabled>Người phụ trách</b-form-select-option>
                                <option v-for="(under) in underUsers" :key="under.id" :value="under.id">
                                    {{ under.name | capitalising }} - {{under.position}}
                                </option>
                            </b-form-select>
                        </b-form-group>
                        <label>Logo</label>
                        <b-form-group col-lg="4">
                            <a-thumbnail label="Chọn logo" :value="form.preview"
                                @input="value => form.preview = value" />
                        </b-form-group>
                        <b-form-group>
                            <label>Thứ tự</label>
                            <b-input type="number" v-model.number="form.order" min="1" max="50" />
                        </b-form-group>
                        <b-form-group>
                            <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                                {{addErrorMessage}}
                            </div>
                            <b-button variant="success" class="mr-2" @click.prevent="create">
                                Lưu
                            </b-button>
                            <b-button variant="danger" @click.prevent="toListView">
                                Huỷ
                            </b-button>
                        </b-form-group>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import AThumbnail from './partials/a-thumbnail';
import {
    httpClient
} from "../../../_utils/httpClient";
import Swal from 'sweetalert2';

export default {
    name: 'create',
    components: {
        AThumbnail,
        PageHeader,
    },

    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Thiết lập người phụ trách',
                to: '/admin/config-assignees',
            }, {
                text: 'Thêm mới',
                to: '#',
                active: true,
            },],
            showAddErrorMessage: false,
            addErrorMessage: '',
            form: {
                name: null,
                preview: '',
                order: 1,
                in_charge: null,
            },
            loading: false,
            underUsers: [],
            categories: [],
            check_categories: []
        };
    },
    watch: {
        'form.name': {
            handler() {
                let img_preview = this.categories.filter(item => (item.name == this.form.name))[0]
                this.form.preview = img_preview.preview
            }
        }
    },
    methods: {
        async toListView() {
            await this.$router.push({
                name: 'admin.config.assignees'
            })
        },
        async getData() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'category'
                }
            })
            if (response.status === 200 && response.data.code === 0) {
                this.entries = response.data.data
            }
        },
        validator() {
            if (this.form.name < 1 || this.form.name == null) {
                this.showAddErrorMessage = true;
                this.addErrorMessage = `Tên danh mục không được bỏ trống`;
                return false;
            }
            if (this.form.in_charge == null) {
                this.showAddErrorMessage = true;
                this.addErrorMessage = `Người phụ trách không được trống`;
                return false;
            }
            return true
        },
        async getUnderUser() {
            let response = await httpClient.get(`user/under`)
            let responseData = response.data;
            if (responseData.code === 0) {
                this.underUsers = responseData.data
            }
        },
        async getCategories() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'category'
                }
            })
            let responseData = response.data;
            if (responseData.code === 0) {
                this.categories = responseData.data
            }
        },
        async create() {
            this.showAddErrorMessage = false
            if (!this.validator()) return
            if (!this.form.order) {
                this.form.order = 1
            }
            let payload = {
                ...this.form,
            }
            await Swal.queue([{
                title: 'Thêm mới người phụ trách danh mục này?',
                confirmButtonText: 'Xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`sentiment-category`, payload)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Thêm mới người phụ trách danh mục thành công',
                        });
                        await this.toListView()
                    } else {
                        Swal.insertQueueStep({
                            title: 'Thêm mới người phụ trách danh mục không thành công',
                        });
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    return true;
                },
            },]);
        },
    },
    async created() {
        let response = await httpClient.get(`sentiment-category`)
        if (response.data.data) {
            response.data.data.forEach(item => {
                this.check_categories.push(item.name)
            });
        }
        await this.getUnderUser();
        await this.getCategories();
        this.getData();
    },
};
</script>

<style lang="scss" scoped>
.action-column {
    width: 120px;
    text-align: center;
}

.red {
    color: red;
}
</style>
